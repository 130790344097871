@font-face {
  font-family: "cheri_filled";
  src: local("cheri_filled"),
    url(./fonts/cheri_filled.TTF) format("truetype");
}

@font-face {
  font-family: "cheri";
  src: local("cheri"),
    url(./fonts/cheri.TTF) format("truetype");
}

a{
  text-decoration: none!important;
}

a:hover{
  color: none!important;
  cursor: pointer;
}

.logo-font{
  font-family: "cheri_filled", sans-serif;
  margin-bottom: 0rem;
  font-size: 2rem;
}

html {
  overflow-x: hidden;
}
body {
  font-family: "Inter" !important;
  font-size: 1em;
  background-color: #7468cc !important;
  color: #fff;
  /* background-image: url("./assets/img/bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat; */
}

.walletActionBtn {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 350px;
  margin: auto;
}
.walletActionBtn input {
  width: 100%;
  margin: 0 1em;
  border: none;
  padding: 0.5em;
  text-align: center;
  color: #000000;
  font-weight: 500;
  font-size: 1.2em;
  font-family: "Inter" !important;
}
.addingBtn {
  width: 50px;
  height: 36px;
  border-radius: 50%;
  border: none;
  background: linear-gradient(0deg, #d16048, #d16048);
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  padding: 0;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Fade Animations */
.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}
/* Page Header */
.pageHeader {
  background-color: white;
  padding: 0.5em 5em !important;
}
.pageHeader .navbar-nav .nav-link {
  color: #373836;
  font-weight: 700;
  font-family: "Inter" !important;
  transition: 200ms all ease-in-out;
  margin-left: 3em;
}
.pageHeader .navbar-nav .nav-link:hover {
  opacity: 0.7;
}
.pageHeader .navbar-nav .nav-link.active {
  color: #d16048;
}
.pageHeader .socialIcons .nav-link {
  margin: 0;
  padding: 0.5em 0.3em !important;
  color: #d16048;
  font-size: 1.2em;
}
.pageHeader .navbar-nav .headerBtn {
  background: linear-gradient(0deg, #d16048, #d16048);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  font-size: 0.9em;
  padding: 0.2em 2em !important;
  font-family: "Inter" !important;
  color: white;
  line-height: 2.9;
  margin-left: 4em;
}
.pageHeader .navbar-nav .headerBtn.active {
  color: white;
}
/* Hero Section */
.HeroSection {
  text-align: center;
  padding: 3em 0 5em 0;
}
.HeroSection .bannerVdo {
  height: 500px;
  border: 8px solid white;
  border-radius: 1em;
  box-shadow: 0 0 10px #00000050;
}
.HeroSection .brandName {
  margin-bottom: 1.5em;
}
.HeroSection .heroImg {
  display: none;
  margin: 0.2em auto;
  width: 100%;
  border: 5px solid white;
  border-radius: 1em;
  box-shadow: 0 0 10px #00000050;
}
.HeroSection .heroText {
  font-size: 1.3em;
  line-height: 1.3;
  color: #fff;
  font-weight: 600;

  width: 88%;
  margin: 2em auto;
}

/* Wallet Section */
.WalletSection {
  padding: 4em 0;
}
.WalletSection img {
  width: 100%;
}
.WalletSection .walletContainer {
  background: #f8b6a8;
  border: 2px solid #000000;
  border-radius: 9px;
  padding: 10.6em 2em;
  text-align: center;
}
.WalletSection .walletContainer .walletText {
  color: rgb(14, 13, 13);
  font-weight: 500;
  font-size: 1.2em;
  font-family: "Inter" !important;
}
.WalletSection .walletContainer .walletTextContainer {
  background: #fef5cc;
  border-radius: 8px;
  padding: 1.2em;
  display: flex;
  align-items: center;
  margin-bottom: 1.2em;
  justify-content: space-between;
}
.WalletSection .walletBtn {
  background: linear-gradient(0deg, #d16048, #d16048);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: inline-block;
  font-size: 1.3em;
  color: white;
  font-weight: 500;
  padding: 0.5em 3em;
  margin-top: 3em;
  text-decoration: none;
  transition: 200ms all ease-in-out;
}
.WalletSection .walletBtn:hover {
  opacity: 0.7;
}

/* Project Section */
.ProjectSection {
  padding: 4em 0;
}
.mainHeading {
  font-family: "Inter" !important;
  color: #fff;
  text-shadow: 0px 2px 1px #000000;
  font-weight: 700;
  font-size: 2.3em;
  margin-bottom: 0.7em;
}
.mainText {
  color: #fff;
  font-size: 1.4em;
  margin-bottom: 1em;
}
.ProjectSection .mainText.odd {
  font-size: 1.3em;
  line-height: 1.3;
}
.ProjectSection img {
  width: 85%;
  box-shadow: 0px 4px 66px 5px rgba(0, 0, 0, 0.25);
}
.ProjectSection .gifImg {
  height: 500px;
}

/* Carousel Section */
.CarouselSection img {
  width: 100%;
  display: unset !important;
}

/* Perks Section */
.PerksSection {
  padding: 5em 0;
}
.PerksSection .mainText.odd {
  font-family: "Inter" !important;
}
.styledText {
  font-family: "Inter" !important;
}
.mainHeading {
  color: #ffffff;
  text-shadow: 0px 3px 1px #000000;
}
.PerksSection .mainText {
  color: #fff;
  line-height: 1.2;
  font-family: "Inter" !important;
}
.PerksSection .perksContainer {
  border: 1.5px solid #443e71;
  border-radius: 12px;
}
.PerksSection .perksContainer .mainText {
  font-size: 1em;
  margin-bottom: 0;
  border-bottom: 1.5px solid #443e71;
  padding: 1em;
  min-height: 55px;
}
.PerksSection .perksContainer .mainText:last-child {
  border-bottom: none;
}
.PerksSection .mainHeading {
  font-size: 2em;
}
.PerksSection .userImg {
  margin-bottom: 1em;
}
.PerksSection .perksContainer .mainText.odd {
  font-weight: 600;
}
.PerksSection .customCol {
  margin-top: 9.7em;
}

/* Faq Section */
.FaqSection {
  padding: 5em 0;
}
.FaqSection .mainHeading,
.TeamSection .mainHeading {
  color: #fff;
  text-align: center;
  text-shadow: none;
  margin-bottom: 1.5em;
}
.FaqSection .accordion-item {
  margin-bottom: 2em;
  background-color: transparent;
  border: none;
  border-radius: 0;
}
.FaqSection .accordion-header {
  border-radius: 0;
}
.FaqSection .accordion-body {
  background-color: white;
  border-radius: 14px;
  margin-top: 0.5em;
}
.FaqSection .accordion-header button {
  box-shadow: none !important;
  background: #ffffff;
  border: 4px solid #443e71;
  border-radius: 14px !important;
  color: #443e71;
  font-size: 0.7em;
  padding: 1.1em 2em;
}
.FaqSection .accordion-button::after {
  background-image: unset;
  content: "+";
  font-size: 2em;
  margin-top: -0.9em;
}
.FaqSection .accordion-button:not(.collapsed)::after {
  background-image: unset;
  content: "-";
  transform: unset;
}

/* Team Section */
.TeamSection {
  padding: 5em 0;
}
.TeamSection .teamCard {
  text-align: center;
}
.TeamSection .teamCard img {
  width: 100%;
}
.TeamSection .teamCard .teamName {
  font-family: "Inter" !important;
  font-weight: 500;
  color: #fff;
  font-size: 1.3em;
  margin-top: 1.8em;
  font-style: italic;
  line-height: 1;
  margin-bottom: 0;
}
.TeamSection .teamCard .teamDec {
  font-family: "Inter" !important;
  font-weight: 500;
  color: white;
}
.TeamSection .teamBtn {
  display: inline-block;
  background: #443e71;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  font-size: 0.9em;
  font-weight: 600;
  color: white;
  padding: 0.6em 3em;
  margin-top: 6em;
  text-decoration: none;
  transition: 200ms all ease-in-out;
}
.TeamSection .teamBtn:hover {
  opacity: 0.7;
  box-shadow: none;
}

/* Footer */
.Footer {
  background-color: #7b7bda;
  padding: 3em 0;
}
.Footer .footerlink {
  display: inline-block;
  text-decoration: none;
  color: white;
  font-family: "Inter" !important;
  font-weight: 400;
  font-size: 0.9em;
  transition: 200ms all ease-in-out;
}
.Footer .footerlink:hover {
  opacity: 0.7;
}
.Footer .contactLinks span {
  display: inline-block;
  color: white;
  font-weight: 200;
  margin: 0 1.2em;
  font-size: 0.9em;
  font-family: "Inter" !important;
}
.Footer .footerlink.odd {
  margin: 0 1em;
  font-size: 0.8em;
}
.Footer .footerHeading {
  font-family: "Inter" !important;
  font-weight: 700;
  color: white;
  font-size: 1em;
}
.Footer .socialIcon {
  display: inline-block;
  text-decoration: none;
  color: white;
  font-size: 1.4em;
  margin-right: 0.5em;
  text-decoration: none;
  transition: 200ms all ease-in-out;
}
.Footer .socialIcon:hover {
  opacity: 0.7;
}
.Footer .copyRight {
  font-family: "Inter" !important;
  font-size: 0.8em;
  font-weight: 400;
  color: white;
  margin-top: 0.4em;
}
.Footer .copyRight span {
  font-weight: 700;
  font-family: "Inter" !important;
}

/* Contact Btn */
.contactBtn {
  background-color: yellow;
  padding: 1em 2em;
  color: black;
  display: inline-block;
  text-decoration: none;
  position: fixed;
  right: 10px;
  bottom: 20px;
  border-radius: 30px;
  transition: 200ms all ease-in-out;
  font-family: "Inter" !important;
}
.contactBtn:hover {
  opacity: 0.7;
}
.contactBtn i {
  font-size: 1.4em;
  vertical-align: top;
}

/* Media */
@media (max-width: 992px) {
 
  .pageHeader {
    padding: 0.5em 1.2em !important;
  }
  .pageHeader .navbar-nav .nav-link {
    margin-left: 0;
  }
  .pageHeader .navbar-nav .headerBtn {
    margin-top: 1em;
    text-align: center;
  }
  .HeroSection .heroText {
    width: 100%;
    font-size: 1.2em;
  }
  .HeroSection {
    padding: 3em 0;
  }
  .HeroSection .bannerVdo {
    display: none;
  }
  .HeroSection .heroImg {
    display: block;
  }
  .HeroSection .brandName {
    width: 50%;
  }
  .WalletSection .walletContainer {
    padding: 4em 2em;
  }
  .PerksSection .mainText {
    font-size: 1em;
  }
  .PerksSection .userImg {
    width: 85%;
  }
  .PerksSection .mainHeading.odd {
    font-size: 1em;
  }
  .PerksSection .mainHeading,
  .FaqSection .mainHeading,
  .TeamSection .mainHeading {
    font-size: 1.6em;
  }
  .ProjectSection img {
    width: 100%;
  }
  .FaqSection .accordion-header button {
    padding: 1em;
    font-size: 0.6em;
  }
  .FaqSection .accordion-item {
    margin-bottom: 1.5em;
  }
  .Footer .footerlink.odd {
    margin: 0 1.5em 0 0;
  }
  .PerksSection .customCol {
    margin-top: 6.2em;
  }
  .PerksSection .perksContainer .mainText {
    padding: 1em 0.5em;
    font-size: 0.8em;
    line-height: 1;
  }
}
